<template>
  <div class="panel-tab__content">

    <el-form :model="flowConditionForm" label-width="90px" size="mini" @submit.native.prevent label-position="top">

      <el-form-item label="流转类型">

        <el-select v-model="flowConditionForm.type" @change="updateFlowType">

          <el-option label="普通流转路径" value="normal" />

          <el-option label="默认流转路径" value="default" />

          <el-option label="条件流转路径" value="condition" />

        </el-select>

      </el-form-item>

      <!-- 选择条件流转要显示条件内容 -->

      <el-form-item label="添加条件" v-if="flowConditionForm.type === 'condition'" key="condition"
        style="margin-bottom: 8px">

        <el-button round type="" @click="addConditionGroup">添加条件组</el-button>


      </el-form-item>

      <!-- <el-form-item label="条件格式" v-if="flowConditionForm.type === 'condition'" key="condition">

        <el-select v-model="flowConditionForm.conditionType">

          <el-option label="表达式" value="expression" />

          <el-option label="脚本" value="script" />

        </el-select>

      </el-form-item>

      <el-form-item label="表达式" v-if="flowConditionForm.conditionType && flowConditionForm.conditionType === 'expression'" key="express">

        <el-input v-model="flowConditionForm.body" style="width:192px;"  clearable @change="updateFlowCondition" />

      </el-form-item> -->

      <template v-if="flowConditionForm.conditionType &&

        flowConditionForm.conditionType === 'script'

      ">

        <el-form-item label="脚本语言" key="language">

          <el-input v-model="flowConditionForm.language" clearable @change="updateFlowCondition" />

        </el-form-item>

        <el-form-item label="脚本类型" key="scriptType">

          <el-select v-model="flowConditionForm.scriptType">

            <el-option label="内联脚本" value="inlineScript" />

            <el-option label="外部脚本" value="externalScript" />

          </el-select>

        </el-form-item>

        <el-form-item label="脚本" v-if="flowConditionForm.scriptType === 'inlineScript'" key="body">

          <el-input v-model="flowConditionForm.body" type="textarea" clearable @change="updateFlowCondition" />

        </el-form-item>

        <el-form-item label="资源地址" v-if="flowConditionForm.scriptType === 'externalScript'" key="resource">

          <el-input v-model="flowConditionForm.resource" clearable @change="updateFlowCondition" />

        </el-form-item>

      </template>

    </el-form>

    <!-- 条件组 -->

    <template>

      <el-form size="mini" :model="conditionForm" ref="validateForm">

        <div v-for="(item, index) in conditionForm.conditionList" :key="item.id" class="card">

          <el-card>

            <div slot="header" class="clearfix">

              <span>条件组</span>

              <el-button style="float: right" icon="el-icon-delete" size="mini" circle
                @click="delCondition(index)"></el-button>

            </div>



            <main v-for="(sub, i) in item.checkConditionList" :key="i">

              <!-- 计步器 -->

              <template v-if="sub.formType == 'number' ||

                sub.formType == 'inumber' ||
                sub.formType == 'date-range'
              ">

                <el-row :gutter="8">

                  <el-col :span="4"> {{ sub.fieldName }}</el-col>

                  <el-col :span="10">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.operator'

                      " :rules="rules">

                      <el-select v-model="sub.operator" style="width: 100%" size="mini">

                        <el-option v-for="item in $store.state.bpm.operatorNumOption" :key="item.id" :label="item.label"
                          :value="item.value" />

                      </el-select> </el-form-item></el-col>

                  <el-col :span="10" v-if="sub.operator == '<' ||

                    sub.operator == '==' ||

                    sub.operator == '>' ||

                    sub.operator == '<=' ||

                    sub.operator == '>='

                  ">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.val1'

                      " :rules="rules">

                      <el-input-number size="mini" style="width: 100%" v-model="sub.val1"></el-input-number>

                    </el-form-item>

                  </el-col>

                </el-row>

                <!-- 介于 -->

                <el-row v-if="sub.operator == 'in'" :gutter="4">

                  <el-col :span="5">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.val1'

                      " :rules="rules">

                      <el-input-number size="mini" style="width: 100%" v-model="sub.val1"
                        controls-position="right"></el-input-number>

                    </el-form-item>

                  </el-col>

                  <el-col :span="5">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.extra1'

                      " :rules="rules">

                      <el-select v-model="sub.extra1" style="width: 100%" size="mini">

                        <el-option v-for="item in $store.state.bpm.extraOption" :key="item.id" :label="item.label"
                          :value="item.value" />

                      </el-select>

                    </el-form-item>

                  </el-col>

                  <el-col :span="4" style="text-align: center; font-size: 12px">

                    {{ sub.fieldName }}

                  </el-col>

                  <el-col :span="5">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.extra2'

                      " :rules="rules">

                      <el-select v-model="sub.extra2" style="width: 100%" size="mini">

                        <el-option v-for="item in $store.state.bpm.extraOption" :key="item.id" :label="item.label"
                          :value="item.value" />

                      </el-select>

                    </el-form-item>

                  </el-col>

                  <el-col :span="5">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.val2'

                      " :rules="rules">

                      <el-input-number style="width: 100%" v-model="sub.val2" size="mini"
                        controls-position="right"></el-input-number>

                    </el-form-item>

                  </el-col>

                </el-row>
              </template>

              <template v-if="sub.formType == 'amountMoney'">

                <el-row :gutter="8">

                  <el-col :span="4"> {{ sub.fieldName }}</el-col>

                  <el-col :span="10">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.operator'

                      " :rules="rules">

                      <el-select v-model="sub.operator" style="width: 100%" size="mini">

                        <el-option v-for="item in $store.state.bpm.operatorNumOption" :key="item.id" :label="item.label"
                          :value="item.value" />

                      </el-select> </el-form-item></el-col>

                  <el-col :span="10" v-if="sub.operator == '<' ||

                    sub.operator == '==' ||

                    sub.operator == '>' ||

                    sub.operator == '<=' ||

                    sub.operator == '>='

                  ">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.val1'

                      " :rules="rules">

                      <el-input-number size="mini" style="width: 100%" v-model="sub.val1" :precision="2"
                        :step="1"></el-input-number>

                    </el-form-item>

                  </el-col>

                </el-row>

                <!-- 介于 -->

                <el-row v-if="sub.operator == 'in'" :gutter="4">

                  <el-col :span="5">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.val1'

                      " :rules="rules">

                      <el-input-number size="mini" style="width: 100%" v-model="sub.val1" :precision="2" :step="1"
                        controls-position="right"></el-input-number>
                        

                    </el-form-item>
                  </el-col>

                  <el-col :span="5">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.extra1'

                      " :rules="rules">

                      <el-select v-model="sub.extra1" style="width: 100%" size="mini">

                        <el-option v-for="item in $store.state.bpm.extraOption" :key="item.id" :label="item.label"
                          :value="item.value" />

                      </el-select>

                    </el-form-item>

                  </el-col>

                  <el-col :span="4" style="text-align: center; font-size: 12px">

                    {{ sub.fieldName }}

                  </el-col>

                  <el-col :span="5">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.extra2'

                      " :rules="rules">

                      <el-select v-model="sub.extra2" style="width: 100%" size="mini">

                        <el-option v-for="item in $store.state.bpm.extraOption" :key="item.id" :label="item.label"
                          :value="item.value" />

                      </el-select>

                    </el-form-item>

                  </el-col>

                  <el-col :span="5">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.val2'

                      " :rules="rules">

                      <el-input-number style="width: 100%" v-model="sub.val2" size="mini"
                        controls-position="right"></el-input-number>

                    </el-form-item>

                  </el-col>

                </el-row>
              </template>

              <template v-if="sub.formType == 'radio'">

                <el-row :gutter="8">

                  <el-col :span="4"> {{ sub.fieldName }}</el-col>

                  <el-col :span="20">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.valList'

                      " :rules="rules">
                      <el-checkbox-group v-model="sub.valList" style="width: 100%">

                        <el-checkbox :label="e.value" v-for="e in sub.optList" :key="e.label">{{ e.label
                          }}</el-checkbox>

                      </el-checkbox-group>

                    </el-form-item>

                  </el-col>

                </el-row>

              </template>

              <!-- 多选框 -->

              <template v-if="sub.formType == 'checkbox'">

                <el-row :gutter="8">

                  <el-col :span="4"> {{ sub.fieldName }}</el-col>

                  <el-col :span="20">

                    <el-row :gutter="8" style="margin-bottom: 0px">

                      <el-col :span="8">

                        <el-form-item :prop="'conditionList.' +

                          index +

                          '.checkConditionList.' +

                          i +

                          '.operator'

                          " :rules="rules">

                          <el-select v-model="sub.operator" style="width: 100%" size="mini">

                            <el-option v-for="item in $store.state.bpm.operatorOption" :key="item.id"
                              :label="item.label" :value="item.value" />

                          </el-select>

                        </el-form-item>

                      </el-col>

                      <el-col :span="16">

                        <el-form-item :prop="'conditionList.' +

                          index +

                          '.checkConditionList.' +

                          i +

                          '.valList'

                          " :rules="rules">

                          <el-select v-model="sub.valList" style="width: 100%" size="mini" multiple>

                            <el-option v-for="item in sub.optList" :key="item.id" :label="item.label"
                              :value="item.value" />

                          </el-select>

                        </el-form-item>

                      </el-col>

                    </el-row>

                  </el-col>

                </el-row>

              </template>
              <!-- 选项关联 -->
              <template v-if="sub.formType == 'selectOptions' || sub.formType == 'select'">

                <el-row :gutter="8">

                  <el-col :span="4"> {{ sub.fieldName }}</el-col>

                  <el-col :span="20">

                    <el-row :gutter="8" style="margin-bottom: 0px">

                      <el-col :span="8">

                        <el-form-item :prop="'conditionList.' +

                          index +

                          '.checkConditionList.' +

                          i +

                          '.operator'

                          " :rules="rules">

                          <el-select v-model="sub.operator" style="width: 100%" size="mini">

                            <el-option v-for="item in $store.state.bpm.operatorOption" :key="item.id"
                              :label="item.label" :value="item.value" />

                          </el-select>

                        </el-form-item>

                      </el-col>

                      <el-col :span="16">

                        <el-form-item :prop="'conditionList.' +

                          index +

                          '.checkConditionList.' +

                          i +

                          '.valList'

                          " :rules="rules">

                          <el-select v-model="sub.valList" style="width: 100%" size="mini" multiple>

                            <el-option v-for="item in sub.optList" :key="item.id" :label="item.label"
                              :value="item.value" />

                          </el-select>

                        </el-form-item>

                      </el-col>

                    </el-row>

                  </el-col>

                </el-row>

              </template>

              <!-- 部门 -->

              <template v-if="sub.formType == 'department'">

                <el-row :gutter="8">

                  <el-col :span="4"> {{ sub.fieldName }}</el-col>

                  <el-col :span="20">
                    <div class="select_box" style="margin-bottom: 8px">
                      <el-tag style="cursor: pointer" type="info" size="mini" @click="addUser(sub.valList, 1)">
                        添加
                      </el-tag>
                      <el-tag type="info" size="mini" v-for="(item, index) in sub.valList" :key="item.value" closable
                        @close="closeUser(index, sub.valList)">
                        {{ item.name }}
                      </el-tag>
                    </div>
                    <el-form-item :prop="'conditionList.' +
                      index +
                      '.checkConditionList.' +
                      i +
                      '.valList'
                      " :rules="rules">
                      <el-cascader style="width: 100%" v-model="sub.valList" :options="deptTreeOptions[0].children"
                        :props="{
                          value: 'deptId',
                          label: 'deptName',
                          multiple: true,
                          checkStrictly: true
                        }"></el-cascader>
                    </el-form-item>
                  </el-col>

                </el-row>

              </template>

              <!-- 发起人 -->
              <template v-if="sub.formType == 'bpm_initiator'">

                <el-row :gutter="8">

                  <el-col :span="4"> {{ sub.fieldName }}</el-col>

                  <el-col :span="20">
                    <div class="select_box" style="margin-bottom: 8px">
                      <el-tag style="cursor: pointer" type="info" size="mini" @click="addUser(sub.valList, 0)">
                        添加
                      </el-tag>
                      <el-tag type="info" size="mini" v-for="(item, index) in sub.valList" :key="item.value" closable
                        @close="closeUser(index, sub.valList)">
                        {{ item.name }}
                      </el-tag>
                    </div>
                  </el-col>
                </el-row>

              </template>


              <p v-if="i != item.checkConditionList.length - 1">且</p>

            </main>



            <el-button size="mini" icon="el-icon-plus" @click="selectionCriteria(index)">添加条件</el-button>

          </el-card>

          <div v-if="index != conditionForm.conditionList.length - 1">或</div>

        </div>

      </el-form>

    </template>
    <el-button type="primary" size="mini" :loading="loading" @click="saveExpression('validateForm')"
      v-if="conditionForm.conditionList.length && flowConditionForm.type === 'condition'"
      style="margin-top: 10px;">保存</el-button>
    <Customdialog v-if='selectionCriteriaVisible' :dialogWidth="'560px'" :title="'选择条件'" :dialogHeight="'200px'"
      :isFooter="true" @closeDialog="selectionCriteriaVisible = false" @submitDialog="affirmCheckList">
      <template slot="dialogMain">
        <div style="padding: 0 24px;">
          <div style="margin-bottom: 10px">

            请选择用来区分审批流程的条件字段 ，已选{{ checkList.length }}个

          </div>

          <el-checkbox-group v-model="checkList" @change="changeCheckList">


            <el-checkbox :label="item.fieldVal" v-for="(item, index) in formInfo" :key="index">{{ item.fieldName

              }}</el-checkbox>

          </el-checkbox-group>
        </div>
      </template>
    </Customdialog>
    <initiatingRange ref="initiatingRange" :selectedList="chooseList" @confirm="getChooseList" :title="title"
      :isUser="isUser" :addType="addType" :radio="radio" :isGroup="isGroup" />
  </div>
</template>



<script>
import initiatingRange from "@/views/initiatingRange";

import { createExpression } from "@/api/flow";
import Customdialog from '@/components/customdialog'

import { mapState } from "vuex";
import { add } from "lodash-es";

export default {

  name: "FlowCondition",
  components: { Customdialog, initiatingRange },

  props: {

    businessObject: Object,

    type: String,

    model: Object,

  },

  data() {

    return {
      chooseList: [],
      ruleForm: {},

      loading: false,

      elementBaseInfo: {},

      conditionIndex: null, //当前条件组索引

      flowConditionForm: {},

      dialogConditionVisible: false,

      selectionCriteriaVisible: false,

      checkList: [],

      conditionForm: {

        conditionList: [],

      },

      formCheck: [],

      rules: {

        required: true,

        message: "不能为空",

        trigger: "blur",

      },

      // conditionList: [], //条件数据
      title: '',
      addType: '3',
      radio: false,
      isUser: false,
      isGroup: false
    };

  },

  created() {

    // console.log(this.deptTreeOptions, "deptTreeOptions");

  },

  watch: {

    businessObject: {

      immediate: true,

      handler() {

        // console.log("businessObject变化", this.businessObject);

        this.$nextTick(() => this.resetFlowCondition());

      },

    },

    // conditionList: {

    //   immediate: false,

    //   deep: true,

    //   handler() {

    //     const attrObj = Object.create(null);

    //     attrObj["conditionList"] = this.conditionList;

    //     window.bpmnInstances.modeling.updateProperties(

    //       this.bpmnElement,

    //       attrObj

    //     );

    //   },

    // },

  },

  computed: {

    ...mapState({

      formInfo: ((state) => {
        var data = [];
        state.process.design.formInfo.forEach(e => {
          if ((e.formType == "number" || e.formType == "radio" || e.formType == "checkbox" || e.formType == "department" || e.formType == "select" || e.formType == "inumber" || e.formType == "date-range" || e.formType == "selectOptions" || e.formType == "amountMoney") && e.isRequired) {
            data.push(e);
          }
          if (e.formType == "rowFormItem") {
            e.children[0].forEach((err) => {
              if (err.__config__._type_ && err.__config__._type_ == "leave" && err.__vModel__ == 'field1724060921495') {
                data.push({
                  formType: err.__config__.type
                    ? err.__config__.type
                    : err.__config__.tagIcon,
                  fieldName: err.__config__.label,
                  fieldVal: err.__vModel__,
                  isRequired: err.__config__.required,
                  optList: e.options,
                });
              }
            })
          }
        });
        data.push({ fieldName: '发起人', fieldVal: 'bpm_initiator', isRequired: false, formType: 'bpm_initiator', valList: [] })
        return data;

        // (state) => state.process.design.formInfo.filter((e) => (
        //   e.formType == "number" ||
        //   e.formType == "radio" ||
        //   e.formType == "checkbox" ||
        //   e.formType == "department" ||
        //   e.formType == "select" ||
        //   e.formType == "inumber" ||
        //   e.formType == "date-range" ||
        //   e.formType == "selectOptions" ||
        //   e.formType == "amountMoney") &&
        //   e.isRequired
        // )
      }),

      // formInfo: (state) => state.process.design.formInfo,

      deptTreeOptions: (state) => state.bpm.deptTreeOptions,

    }),

  },

  methods: {



    // 保存条件组

    saveExpression(formName) {

      let str =


        // return

        this.$refs[formName].validate((valid) => {

          if (valid) {

            let obj = {

              modelKey: this.model.key,

              edgeId: this.elementBaseInfo.id,

              expression: [],

            };
            let strList = []

            obj.expression = this.conditionForm.conditionList.map((e, index) => {

              let operatorConditionList = [];

              let singleChoiceList = [];

              let multipleChoiceList = [];

              let deptChoiceList = [];
              let bpmInitiator = []
              strList.push([])



              if (e.checkConditionList && e.checkConditionList.length) {

                e.checkConditionList.map((i) => {

                  if (i.formType == "number") {

                    operatorConditionList.push({

                      field: i.fieldVal,

                      operator: i.operator,

                      extra1: i.extra1 || "",

                      val1: i.val1,

                      extra2: i.extra2 || "",

                      val2: i.val2,

                    });
                    if (i.operator == 'in') {
                      strList[index].push(`${i.val1?.toFixed(1)} ${i.extra1} ${i.fieldName}  ${i.extra2} ${i.val2?.toFixed(1)}`)
                    } else {
                      strList[index].push(`${i.fieldName} ${i.operator} ${i.val1}`)
                    }


                  }
                  if (i.formType == "date-range") {
                    operatorConditionList.push({

                      field: i.fieldVal,

                      operator: i.operator,

                      extra1: i.extra1 || "",

                      val1: i.val1?.toFixed(1),

                      extra2: i.extra2 || "",

                      val2: i.val2?.toFixed(1),

                    });
                    if (i.operator == 'in') {
                      strList[index].push(`${i.val1?.toFixed(1)} ${i.extra1} ${i.fieldName}  ${i.extra2} ${i.val2?.toFixed(1)}`)
                    } else {
                      strList[index].push(`${i.fieldName} ${i.operator} ${i.val1}`)
                    }
                    // strList.push(`${i.fieldName} ${i.operator} ${i.val1}`)

                  }

                  if (i.formType == "amountMoney") {
                    console.log(`output->11`,i)

                    operatorConditionList.push({

                      field: i.fieldVal,

                      operator: i.operator,

                      extra1: i.extra1 || "",

                      val1: i.val1?.toFixed(2),

                      extra2: i.extra2 || "",

                      val2: i.val2?.toFixed(2),

                    });
                    if (i.operator == 'in') {
                      strList[index].push(`${i.val1?.toFixed(1)} ${i.extra1} ${i.fieldName}  ${i.extra2} ${i.val2?.toFixed(1)}`)
                    } else {
                      strList[index].push(`${i.fieldName} ${i.operator} ${i.val1}`)
                    }

                  }

                  if (i.formType == "radio") {

                    singleChoiceList.push({

                      field: i.fieldVal,

                      valList: i.valList,

                    });
                    let arr = i.valList.map(e => e).join('或')
                    strList[index].push(`${i.fieldName} 选中 ${arr}`)

                  }

                  if (i.formType == "checkbox" || i.formType == "selectOptions" || i.formType == "select") {

                    multipleChoiceList.push({

                      field: i.fieldVal,

                      operator: i.operator,

                      valList: i.valList,

                    });
                    console.log(`output->`, i.valList)
                    let arr = i.valList.map(e => e).join(',')
                    if (i.operator == 'partEqual') {
                      strList[index].push(`${i.fieldName} 部分等于 ${arr}`)
                    } else {
                      strList[index].push(`${i.fieldName} 完全等于 ${arr}`)
                    }
                  }

                  if (i.formType == "department") {

                    // deptChoiceList.push({

                    //   field: i.fieldVal,

                    //   valList: i.valList.map((e) => {

                    //     return e[e.length - 1];

                    //   }),

                    // });
                    deptChoiceList.push({

                      field: i.fieldVal,

                      valList: i.valList.map((e) => e.id),

                    });
                    let arr = []
                    i.valList.map(e => {
                      arr.push(e.name)
                    })
                    strList[index].push(`${i.fieldName} 属于 ${arr.join(',')}`)
                  }
                  if (i.formType == "bpm_initiator") {
                    bpmInitiator.push({

                      field: i.fieldVal,
                      valList: i.valList,
                    });
                    let arr = i.valList.map(e => e.name).join(',')
                    strList[index].push(`${i.fieldName} 属于 ${arr}`)
                  }
                });
              }
              return {

                operatorConditionList,

                singleChoiceList,

                multipleChoiceList,

                deptChoiceList,
                bpmInitiator,
              };

            });

            if (obj.expression) {

              this.createExpression(obj, strList);

            }

          } else {

            return false;

          }

        });

    },

    async createExpression(obj, strList) {

      this.loading = true;
      // console.log(`output->obj`, obj)
      // console.log(`output->strList`, strList)

      createExpression(obj)

        .then((res) => {
          if (res.code == 200) {
            let data = res.data;
            this.flowConditionForm.body = data;
            this.updateFlowCondition();
            const attrObj = Object.create(null);
            attrObj["conditionList"] = JSON.stringify(
              this.conditionForm.conditionList
            );
            let strArr = strList.map(e =>
              e.join(' 且 ')
            )
            let str = strArr.join(' 或 ')
            if (str.length > 25) {
              str = str.slice(0, 25) + '...';
            }
            attrObj["name"] = str
            // this.bpmnElement.businessObject.name=str
            window.bpmnInstances.modeling.updateProperties(
              this.bpmnElement,
              attrObj
            );
            this.loading = false;

            this.$message.success("保存成功");
          } else {
            this.$message.error(res.msg);
            this.loading = false;
          }


        })

        .catch((res) => {

          this.loading = false;

        });



      // console.log(data);

    },
    findNameById(tree, id) {
      for (const node of tree) {
        if (node.deptId === id) {
          return node.deptName; // 找到匹配的id，返回对应的name
        }
        if (node.children && node.children.length > 0) {
          const foundName = this.findNameById(node.children, id); // 递归查找子节点
          if (foundName) {
            return foundName; // 如果在子节点中找到了，则返回结果
          }
        }
      }
      return ''; // 如果没有找到，返回null
    },

    // 生产条件表达式

    // async createExpression() {

    //   const { data, code } = await createExpression();

    // },

    affirmCheckList() {

      // console.log(this.checkList);

      let list = [];

      let arr = JSON.parse(JSON.stringify(this.formInfo));
      let arrA = arr.filter(item => this.checkList.includes(item.fieldVal));
      if (this.conditionForm.conditionList[this.conditionIndex].checkConditionList) {
        list.push(...this.conditionForm.conditionList[this.conditionIndex].checkConditionList)
      }

      console.log(`output->arrA`, arrA)

      // 添加 arrA 中的对象到 arrB，如果 arrB 中没有对应的 id
      arrA.forEach(itemA => {
        if (!list.some(itemB => itemB.fieldVal === itemA.fieldVal)) {
          if (itemA.formType == "checkbox" || itemA.formType == "radio") {

            itemA.valList = [];

          }
          list.push(itemA);
        }
      });

      // 从 arrB 中删除那些不在 arrA 中的对象
      list = list.filter(itemB => arrA.some(itemA => itemA.fieldVal === itemB.fieldVal));

      console.log(`output->list`, list)



      // arr.filter((e) => {

      //   this.checkList.filter((i) => {

      //     if (e.fieldVal == i) {

      //       if (e.formType == "checkbox" || e.formType == "radio") {

      //         e.valList = [];

      //       }

      //       list.push(e);

      //     }

      //   });

      // });

      this.$set(

        this.conditionForm.conditionList[this.conditionIndex],

        "checkConditionList",

        list

      );

      this.conditionForm.conditionList[this.conditionIndex].checkList =

        this.checkList;

      this.selectionCriteriaVisible = false;

    },

    changeCheckList(e) {

      // this.conditionList[this.conditionIndex].checkList = e;

    },

    //点击添加条件

    selectionCriteria(index) {

      this.conditionIndex = index;

      this.checkList = this.conditionForm.conditionList[index].checkList;

      this.selectionCriteriaVisible = true;

    },



    //点击添加条件组

    addConditionGroup() {

      if (this.conditionForm.conditionList.length >= 3) {

        this.$message.error("最多添加三个条件组");

      } else {

        this.conditionForm.conditionList.push({

          checkList: [],

        });

      }

    },

    // 删除条件组

    delCondition(index) {

      this.conditionForm.conditionList.splice(index, 1);
      if (this.conditionForm.conditionList.length > 0) {
        this.saveExpression('validateForm')
      } else {
        let data = "";
        this.flowConditionForm.body = data;
        this.updateFlowCondition();
        const attrObj = Object.create(null);
        attrObj["conditionList"] = JSON.stringify(
          this.conditionForm.conditionList
        );
        attrObj["name"] = ''
        window.bpmnInstances.modeling.updateProperties(
          this.bpmnElement,
          attrObj
        );
        this.loading = false;
        this.$message.success("保存111成功");
      }
    },

    resetFlowCondition() {

      // console.log(this.formInfo, "forminfo");

      this.bpmnElement = window.bpmnInstances.bpmnElement;

      this.bpmnElementSource = this.bpmnElement.source;

      this.bpmnElementSourceRef = this.bpmnElement.businessObject.sourceRef;

      this.elementBaseInfo = JSON.parse(

        JSON.stringify(this.bpmnElement.businessObject)

      );



      let { conditionList } = this.bpmnElement.businessObject.$attrs;

      this.conditionForm.conditionList = conditionList

        ? JSON.parse(conditionList)

        : [];



      if (

        this.bpmnElementSourceRef &&

        this.bpmnElementSourceRef.default &&

        this.bpmnElementSourceRef.default.id === this.bpmnElement.id

      ) {

        // 默认

        this.flowConditionForm = { type: "default" };

      } else if (!this.bpmnElement.businessObject.conditionExpression) {

        // 普通

        this.flowConditionForm = { type: "normal" };

      } else {

        // 带条件

        const conditionExpression =

          this.bpmnElement.businessObject.conditionExpression;

        this.flowConditionForm = { ...conditionExpression, type: "condition" };

        // resource 可直接标识 是否是外部资源脚本

        if (this.flowConditionForm.resource) {

          this.$set(this.flowConditionForm, "conditionType", "script");

          this.$set(this.flowConditionForm, "scriptType", "externalScript");

          return;

        }

        if (conditionExpression.language) {

          this.$set(this.flowConditionForm, "conditionType", "script");

          this.$set(this.flowConditionForm, "scriptType", "inlineScript");

          return;

        }

        this.$set(this.flowConditionForm, "conditionType", "expression");

      }

    },

    updateFlowType(flowType) {

      // 正常条件类

      if (flowType === "condition") {

        // console.log("正常条件类", this.flowConditionRef);

        this.flowConditionRef = window.bpmnInstances.moddle.create(

          "bpmn:FormalExpression"

        );

        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {

          conditionExpression: this.flowConditionRef,

        });

        this.conditionForm.conditionList = [];

        const attrObj = Object.create(null);

        attrObj["conditionList"] = JSON.stringify(

          this.conditionForm.conditionList

        );

        window.bpmnInstances.modeling.updateProperties(

          this.bpmnElement,

          attrObj

        );

        return;

      }

      // 默认路径

      if (flowType === "default") {

        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {

          conditionExpression: null,

        });

        window.bpmnInstances.modeling.updateProperties(this.bpmnElementSource, {

          default: this.bpmnElement,

        });

        this.conditionForm.conditionList = [];

        const attrObj = Object.create(null);

        attrObj["conditionList"] = JSON.stringify(

          this.conditionForm.conditionList

        );

        window.bpmnInstances.modeling.updateProperties(

          this.bpmnElement,

          attrObj

        );

        return;

      }

      // 正常路径，如果来源节点的默认路径是当前连线时，清除父元素的默认路径配置

      if (

        this.bpmnElementSourceRef.default &&

        this.bpmnElementSourceRef.default.id === this.bpmnElement.id

      ) {

        window.bpmnInstances.modeling.updateProperties(this.bpmnElementSource, {

          default: null,

        });

      }

      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {

        conditionExpression: null,

      });

    },

    updateFlowCondition() {

      // 将body进行赋值

      // console.log("updateFlowCondition", this.flowConditionForm);

      let { conditionType, scriptType, body, resource, language } =

        this.flowConditionForm;

      let condition;

      // console.log(conditionType, "conditionType");

      // return;



      if (conditionType === "expression") {

        condition = window.bpmnInstances.moddle.create(

          "bpmn:FormalExpression",

          { body }

        );

      } else {

        if (scriptType === "inlineScript") {

          condition = window.bpmnInstances.moddle.create(

            "bpmn:FormalExpression",

            { body, language }

          );

          this.$set(this.flowConditionForm, "resource", "");

        } else {

          this.$set(this.flowConditionForm, "body", "");

          condition = window.bpmnInstances.moddle.create(

            "bpmn:FormalExpression",

            { resource, language }

          );

        }

      }

      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {

        conditionExpression: condition,

      });

    },
    addUser(arr, index) {
      // this.initscope()
      if (index == 1) {

        this.title = '选择部门'
        this.addType = '0'
        this.radio = false
        this.isGroup = true
        this.isUser = false
      } else {
        this.title = '选择发起人'
        this.addType = '3'
        this.radio = false
        this.isUser = false
        this.isGroup = false
      }
      console.log(`output->arr`, arr)
      this.chooseList = arr;
      this.$refs.initiatingRange.dialogVisible = true;
      this.$refs.initiatingRange.ifRole = true;

    },
    closeUser(index, arr) {
      arr.splice(index, 1);
    },
    getChooseList(e) {
      if (this.addType == '3') {
        let l = e.map(item => ({ id: item.id, name: item.name, type: item.type }))
        this.chooseList.splice(0)
        this.chooseList.push(...l)
      } else if (this.addType == '0') {
        console.log(`output->e`, e)
        let l = e.map(item => ({ id: item.id, name: item.name, type: item.type }))
        this.chooseList.splice(0)
        this.chooseList.push(...l)
      }
    },

  },

  beforeDestroy() {

    this.bpmnElement = null;

    this.bpmnElementSource = null;

    this.bpmnElementSourceRef = null;

  },


};

</script>

<style scoped lang="scss">
.el-row {

  display: flex;

  /* align-items: center; */

  margin-bottom: 14px;

}



::v-deep .el-form-item__content {

  margin-left: 0 !important;

}

.select_box {
  min-height: 28px;
  widows: 200%;
  border: 1px solid #dddfe5;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  width: 100%;

  gap: 8px;
  flex-wrap: wrap;
}

.select_box:hover {
  border: 1px solid #448ef7;
}



// ::v-deep .el-form-item__error {

//   display: none !important;

// }</style>
